body {
  margin: 0;
  background-color: #333;
  color: #aaa;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
main {
  margin: auto;
  width: 1000px;
}

button {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px rgba(0, 0, 0, 0.15) solid;
  padding: 5px 5px 1px 5px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: button;
  border-radius: 0;
}

button:hover {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.3);
}

button:disabled,
button:disabled:hover {
  color: var(--buttonDisabledText);
  background-color: var(--buttonDisabled);
  background-image: none;
  cursor: auto;
}

input[type="text"] {
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 0;
  color: #bbb;
  padding: 6px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
  font-size: 18px;
  outline: none;
}

.toolbar {
  padding: 20px 5px;
  background-color: rgba(255, 255, 255, 0.1);
  display: grid;
  grid-template-columns: 32px auto 32px;
  font-size: 20px;
  border-bottom: 1px #333 solid;
}
.toolbar-add {
  grid-column: 1;
}
.toolbar-title {
  grid-column: 2;
  text-align: center;
}
.toolbar-delete {
  grid-column: 3;
}

.overlay {
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1px);
}

.list-header {
  display: grid;
  grid-template-columns: 32px;
  align-items: center;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.075);
  border-bottom: 1px #333 solid;
}
.list-header-cols {
  font-weight: bold;
  color: #bbb;
}

.list-item {
  display: grid;
  grid-template-columns: 32px;
  align-items: center;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  border-bottom: 1px #333 solid;
}
.list-item:hover {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.075),
    rgba(255, 255, 255, 0.05)
  );
}

.list-item-col {
  grid-column: 1;
  text-align: center;
}

.list-footer {
  display: grid;
  grid-template-columns: auto 32px 32px;
  background-color: rgba(255, 255, 255, 0.075);
  align-items: center;
}
.list-prev {
  grid-column: 2;
}
.list-next {
  grid-column: 3;
}

.small {
  font-size: 14px;
  color: #777;
}
.center {
  text-align: center;
}

.required {
  font-size: 14px;
  color: red;
}

.spin-container {
  position: absolute;
  z-index: 51;
  text-align: center;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
}
.spinner {
  width: 50px;
  height: 50px;
}
.spin {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
